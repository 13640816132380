.not-found {
  text-align: center; }
  .not-found img {
    width: 90%;
    max-width: 520px;
    margin-top: 65px; }
  .not-found h6 {
    font-size: 36px;
    font-weight: 700;
    margin: 70px 0 150px; }
  @media (max-width: 992px) {
    .not-found {
      width: 80%;
      margin: 0 auto; }
      .not-found img {
        margin-top: 35px; }
      .not-found h6 {
        font-size: 24px;
        margin: 45px 0 50px;
        padding: 0 30px; } }
