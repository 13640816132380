.calendar-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }

.tail-datetime-calendar, .select-time {
  width: 100%;
  display: block;
  overflow: hidden; }
  .tail-datetime-calendar::after, .select-time::after {
    clear: both;
    content: "";
    display: block;
    font-size: 0;
    visibility: hidden; }
  .tail-datetime-calendar .calendar-navi, .select-time .calendar-navi {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 17px; }
    @media (max-width: 576px) {
      .tail-datetime-calendar .calendar-navi > div:first-child, .select-time .calendar-navi > div:first-child {
        display: none; } }
    .tail-datetime-calendar .calendar-navi p, .select-time .calendar-navi p {
      color: #183150;
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      padding-left: 35px;
      background-image: url("../../assets/img/calendar.svg");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;
      margin-bottom: 0; }
      .tail-datetime-calendar .calendar-navi p.choose-time, .select-time .calendar-navi p.choose-time {
        background-image: url("../../assets/img/time.svg"); }
    .tail-datetime-calendar .calendar-navi .calendar-switch, .select-time .calendar-navi .calendar-switch {
      display: flex;
      align-items: center;
      text-transform: capitalize; }
      @media (max-width: 576px) {
        .tail-datetime-calendar .calendar-navi .calendar-switch, .select-time .calendar-navi .calendar-switch {
          margin: 0 auto; } }
    .tail-datetime-calendar .calendar-navi span, .select-time .calendar-navi span {
      color: #183150;
      font-size: 18px;
      font-weight: 600;
      margin-right: 20px; }
      .tail-datetime-calendar .calendar-navi span:first-child, .select-time .calendar-navi span:first-child {
        margin-right: 5px; }
      .tail-datetime-calendar .calendar-navi span.calendar-button, .select-time .calendar-navi span.calendar-button {
        width: 20px;
        height: 20px;
        background-position: center;
        display: inline-block;
        background-repeat: no-repeat; }
      .tail-datetime-calendar .calendar-navi span.button-prev, .select-time .calendar-navi span.button-prev {
        background-image: url("../../assets/img/calendar-right.svg");
        transform: rotate(180deg);
        cursor: pointer; }
      .tail-datetime-calendar .calendar-navi span.button-next, .select-time .calendar-navi span.button-next {
        background-image: url("../../assets/img/calendar-right.svg");
        margin-right: 0;
        cursor: pointer; }

.calendar-date {
  margin: 0;
  padding: 0;
  display: block; }
  .calendar-date table {
    width: 100%; }
    .calendar-date table thead {
      border: 1px solid #dae6f6; }
      .calendar-date table thead tr > * {
        width: 14.2%;
        height: 45px;
        padding: 0;
        text-align: center;
        color: rgba(24, 49, 80, 0.6);
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        text-transform: capitalize; }
    .calendar-date table tbody tr > * {
      width: 14.2%;
      height: 76px;
      padding: 10px 15px;
      line-height: 35px;
      color: #183150;
      font-size: 18px;
      font-weight: 400;
      border: 1px solid #dae6f6;
      cursor: pointer;
      vertical-align: top;
      transition: all 0.2s ease-in-out; }
      @media (max-width: 576px) {
        .calendar-date table tbody tr > * {
          padding: 4px;
          height: 66px; } }
      .calendar-date table tbody tr > * > span {
        margin-bottom: 7px;
        display: inline-flex; }
      .calendar-date table tbody tr > *.today {
        background-color: #edf6ff; }
      .calendar-date table tbody tr > *.other-month {
        opacity: 0.3; }
      .calendar-date table tbody tr > *.disabled-days {
        opacity: 0.3;
        pointer-events: none; }
      .calendar-date table tbody tr > *.empty-days {
        opacity: 0.3;
        pointer-events: none; }
      .calendar-date table tbody tr > *:hover {
        background-color: #edf6ff;
        transition: all 0.2s ease-in-out; }
      .calendar-date table tbody tr > * .busy-hours {
        display: flex;
        justify-content: space-between; }
        @media (max-width: 576px) {
          .calendar-date table tbody tr > * .busy-hours {
            justify-content: flex-start; } }
        .calendar-date table tbody tr > * .busy-hours .default-bar {
          width: 2px;
          height: 24px;
          background-color: #dadada;
          display: block;
          position: relative; }
          @media (max-width: 576px) {
            .calendar-date table tbody tr > * .busy-hours .default-bar {
              margin-right: 1px;
              width: 1px;
              height: 12px; } }
          .calendar-date table tbody tr > * .busy-hours .default-bar i {
            position: absolute;
            height: 12px;
            left: 0;
            bottom: 0;
            width: 100%;
            transition: all 0.2s ease-in-out; }
          .calendar-date table tbody tr > * .busy-hours .default-bar i.not-busy {
            background-color: #7ed321; }
          .calendar-date table tbody tr > * .busy-hours .default-bar i.busy {
            background-color: #f5a623; }
          .calendar-date table tbody tr > * .busy-hours .default-bar i.very-busy {
            background-color: #fe243f; }

.select-time .calendar-navi span:first-child {
  margin-right: 20px; }

.select-time .item {
  min-height: 100px;
  border: 1px solid #dae6f6;
  padding: 10px 15px 10px 7px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer; }
  @media (max-width: 576px) {
    .select-time .item {
      width: 50%; } }
  @media (max-width: 430px) {
    .select-time .item {
      width: 100%;
      margin-top: -1px; } }
  .select-time .item:hover {
    text-decoration: none;
    background-color: #edf6ff;
    transition: background-color 0.2s ease-in-out; }
  .select-time .item > span {
    color: #183150;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 17px;
    display: inline-block;
    position: relative; }
    .select-time .item > span:after {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      right: -16px;
      top: 50%;
      margin-top: -4px;
      position: absolute;
      background-color: #7ed321; }
    .select-time .item > span.busy:after {
      background-color: #f5a623; }
    .select-time .item > span.very-busy:after {
      background-color: #fe243f; }
    .select-time .item > span.disabled:after {
      background-color: #fe243f; }
  .select-time .item .available-count {
    position: absolute;
    top: 10px;
    right: 10px; }
  .select-time .item .reservation-info {
    border-radius: 3px;
    padding: 5px 10px; }
    .select-time .item .reservation-info p {
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      line-height: 17px;
      margin-bottom: 1px; }
    .select-time .item .reservation-info span {
      color: #ffffff;
      font-size: 13px;
      font-weight: 400; }
  .select-time .item.free .reservation-info {
    display: none;
    background-color: #4a96d2; }
  .select-time .item.partly .reservation-info {
    background-color: #4a96d2; }
  .select-time .item.closed {
    background-image: url("../../assets/img/pattern.svg");
    background-size: cover;
    pointer-events: none; }
    .select-time .item.closed .reservation-info {
      background-color: #fe243f; }
  .select-time .item.not-available {
    background-image: url("../../assets/img/pattern.svg");
    background-size: cover;
    pointer-events: none; }
    .select-time .item.not-available .reservation-info {
      display: none; }
    .select-time .item.not-available span:after {
      background-color: #fe243f; }
  .select-time .item.placeholder {
    flex: 0 0 100%;
    max-width: 100%;
    border: none;
    min-height: 400px;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: none;
    pointer-events: none; }
    .select-time .item.placeholder:hover {
      background: none; }
    .select-time .item.placeholder .placeholder-span {
      display: none; }
    .select-time .item.placeholder .reservation-info {
      text-align: center; }
      .select-time .item.placeholder .reservation-info .item-status, .select-time .item.placeholder .reservation-info span {
        color: #183150;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400; }
      .select-time .item.placeholder .reservation-info .item-status {
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 15px; }

.usage-description {
  margin-top: 15px; }
  .usage-description div {
    float: left;
    margin: 0 30px;
    position: relative; }
    .usage-description div:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      display: block;
      left: -18px;
      border-radius: 50%;
      top: 50%;
      margin-top: -4px; }
    .usage-description div.not-busy:after {
      background-color: #7ed321; }
    .usage-description div.busy:after {
      background-color: #f5a623; }
    .usage-description div.very-busy:after {
      background-color: #fe243f; }
  .usage-description p {
    opacity: 0.7;
    color: rgba(24, 49, 80, 0.7);
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 0; }

.brackets-description {
  opacity: 0.7;
  color: rgba(24, 49, 80, 0.7);
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0; }
  .brackets-description p {
    margin-left: 8px; }
