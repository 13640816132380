.form-wrap {
  background-color: #16447c;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 100px; }
  .form-wrap .absolute-bg {
    position: fixed;
    width: 110%;
    left: -5%;
    bottom: -40vh; }
  .form-wrap h1 {
    padding: 90px 0 50px;
    text-align: center;
    color: #fff;
    font-size: 48px;
    font-weight: 700;
    line-height: 58px; }
  .form-wrap .history-back {
    position: fixed;
    top: 35px;
    right: 60px;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-weight: 400;
    cursor: pointer; }
    .form-wrap .history-back svg {
      margin-left: 20px;
      transition: all 0.2s ease-in-out; }
    .form-wrap .history-back:hover svg {
      opacity: 0.6;
      transition: all 0.2s ease-in-out; }
  .form-wrap__content {
    max-width: 580px;
    margin: 0 auto 90px;
    background: #fff;
    border-top: 4px solid #fe243f;
    position: relative; }
    @media (max-width: 610px) {
      .form-wrap__content {
        margin: 0 15px 90px; } }
    .form-wrap__content form .inputs {
      padding: 35px 35px 0 35px; }
      .form-wrap__content form .inputs .form-group {
        margin-bottom: 36px;
        float: left;
        width: 100%; }
    .form-wrap__content form .form-button {
      background-color: #fe243f;
      width: 100%;
      min-height: 93px;
      color: #ffffff;
      font-size: 19px;
      font-weight: 400;
      border: none;
      cursor: pointer; }
      .form-wrap__content form .form-button:focus {
        outline: none; }
      .form-wrap__content form .form-button:disabled {
        opacity: .65; }
    .form-wrap__content .login {
      position: absolute;
      bottom: -45px;
      width: 100%;
      text-align: center;
      color: #ffffff;
      font-size: 19px;
      font-weight: 400;
      text-decoration: underline; }

.validation {
  background-color: #16447c;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .validation .absolute-bg {
    position: fixed;
    width: 110%;
    left: -5%;
    bottom: -40vh; }
  .validation .email-verification {
    text-align: center;
    max-width: 580px;
    padding: 0 15px; }
    .validation .email-verification h1 {
      color: #ffffff;
      font-size: 48px;
      font-weight: 500;
      line-height: 58px;
      margin-bottom: 60px; }
    .validation .email-verification p {
      color: #ffffff;
      font-family: "Source Sans Pro";
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 30px; }
      .validation .email-verification p .info {
        display: inline-flex;
        width: 28px;
        height: 28px;
        border: 1px solid #ffffff;
        justify-content: center;
        line-height: 28px;
        border-radius: 50%;
        margin-right: 12px; }
      .validation .email-verification p span:last-child {
        border-bottom: 1px solid #fff;
        cursor: pointer;
        transition: all 0.2s ease-in-out; }
        .validation .email-verification p span:last-child:hover {
          border-bottom: 1px solid transparent;
          transition: all 0.2s ease-in-out; }

.reset {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
