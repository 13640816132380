@font-face {
  font-family: 'DIN Alternate';
  src: url("../assets/fonts/DINAlternate-Bold.woff2") format("woff2"), url("../assets/fonts/DINAlternate-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Avenir';
  src: url("../assets/fonts/Avenir-Heavy.woff2") format("woff2"), url("../assets/fonts/Avenir-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html {
  height: 100%;
  overflow: auto; }
  html.no-scroll {
    overflow-y: hidden; }

body {
  height: 100%; }
  body.no-scroll {
    overflow-y: hidden; }

a, a:hover {
  color: #010000; }

::-moz-selection {
  color: #000;
  background: #93c0c7; }

::selection {
  color: #000;
  background: #93c0c7; }

.col-visible-mobile {
  display: none; }
  @media (max-width: 992px) {
    .col-visible-mobile {
      display: block; } }

.col-hidden-mobile {
  display: none; }
  @media (min-width: 992px) {
    .col-hidden-mobile {
      display: block; } }

.no-padding {
  padding: 0; }

.hidden {
  display: none; }

.app-content {
  overflow-x: hidden; }

.seperator {
  width: 100%;
  height: 1px;
  background: rgba(74, 150, 210, 0.3);
  clear: both; }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }
