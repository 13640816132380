.form-group {
  position: relative;
  margin-bottom: 50px; }
  .form-group .error-msg, .form-group .success-msg {
    position: absolute;
    bottom: -20px;
    left: 0;
    color: #fe243f;
    font-size: 14px;
    font-weight: 400; }
    .form-group .error-msg.gray, .form-group .success-msg.gray {
      color: rgba(24, 49, 80, 0.7); }
  .form-group .success-msg {
    color: #183150; }
  .form-group .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px; }
  .form-group.error .form-control {
    border: 1px solid #fe243f; }
    .form-group.error .form-control:focus {
      border: 1px solid #fe243f !important; }
  .form-group.error .form-control-placeholder {
    color: #fe243f;
    top: 0;
    font-size: 14px;
    transform: translateY(0);
    opacity: 1;
    background: #fff;
    transition: all 0.2s ease-in-out; }
  .form-group.error svg path {
    fill: #fe243f;
    fill-opacity: 1; }
  .form-group .form-control-placeholder {
    position: absolute;
    top: 50%;
    left: 14px;
    color: rgba(24, 49, 80, 0.7);
    font-size: 18px;
    margin-bottom: 0;
    margin-top: -12px;
    background: transparent;
    padding: 4px 6px;
    border-radius: 3px;
    transition: all 0.2s ease-in-out; }
  .form-group .form-control {
    height: 57px;
    border-radius: 3px;
    border: 1px solid #b5bfcb;
    background-color: #ffffff;
    color: #183150;
    padding: 0 50px 0 20px;
    font-size: 18px;
    font-weight: 400; }
    .form-group .form-control:focus {
      box-shadow: none; }
  .form-group .form-control:focus + .form-control-placeholder,
  .form-group .form-control:valid + .form-control-placeholder {
    top: 0;
    font-size: 14px;
    transform: translateY(0);
    opacity: 1;
    background: #fff;
    transition: all 0.2s ease-in-out; }

.accept-rules {
  /* Default State */
  /* Toggled State */ }
  .accept-rules label {
    color: #183150;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    padding-left: 40px;
    line-height: 25px;
    margin-bottom: 0; }
    .accept-rules label a:hover {
      text-decoration: none; }
  .accept-rules p {
    margin-bottom: 30px; }
    .accept-rules p a {
      border-bottom: 1px solid #183150;
      transition: all 0.2s ease-in-out; }
      .accept-rules p a:hover {
        border-bottom: 1px solid transparent;
        transition: all 0.2s ease-in-out; }
  .accept-rules input[type=checkbox] {
    display: none; }
  .accept-rules div {
    width: 25px;
    height: 25px;
    border: 1px solid #183150;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.2s ease-in-out; }
    .accept-rules div:after {
      opacity: 0;
      content: '';
      position: absolute;
      width: 15px;
      height: 10px;
      border-left: 3px solid #ffffff;
      border-bottom: 3px solid #ffffff;
      transform: rotate(-45deg);
      left: 4px;
      top: 4px;
      transition: all 0.2s ease-in-out; }
  .accept-rules input[type=checkbox]:checked ~ div {
    background: #16447c;
    transition: all 0.2s ease-in-out; }
    .accept-rules input[type=checkbox]:checked ~ div:after {
      content: '';
      opacity: 1;
      transition: all 0.2s ease-in-out; }
  .accept-rules.error div {
    border-color: #fe243f; }
  .accept-rules.error p, .accept-rules.error a {
    color: #fe243f;
    border-color: #fe243f; }

.forgot-pass {
  opacity: 0.5;
  color: #183150;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  display: block;
  text-align: right;
  margin-bottom: 25px;
  margin-top: -30px;
  float: right; }
