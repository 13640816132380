.textdoc {
  padding-top: 47px;
  padding-bottom: 80px; }
  @media (max-width: 768px) {
    .textdoc {
      padding-top: 30px;
      padding-bottom: 40px; } }
  .textdoc h1 {
    color: #16447c;
    font-family: "DIN Alternate";
    font-size: 48px;
    font-weight: 700;
    line-height: 79px;
    margin: 0 0 31px; }
    @media (max-width: 768px) {
      .textdoc h1 {
        line-height: 1.2; } }
  .textdoc .textdoc__content h1 {
    font-family: "DIN Alternate";
    font-size: 48px;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 20px; }
  .textdoc .textdoc__content h2 {
    font-family: "DIN Alternate";
    font-size: 36px;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 20px; }
  .textdoc .textdoc__content h3 {
    font-family: "DIN Alternate";
    font-size: 28px;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 20px; }
  .textdoc .textdoc__content h4 {
    font-family: "DIN Alternate";
    font-size: 20px;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 20px; }
  .textdoc .textdoc__content p {
    line-height: 1.3;
    margin-bottom: 20px; }
  .textdoc .textdoc__content ul, .textdoc .textdoc__content ol {
    margin: 0 0 20px;
    padding-left: 20px; }
    .textdoc .textdoc__content ul li, .textdoc .textdoc__content ol li {
      font-size: 16px;
      line-height: 1.3; }
  .textdoc .textdoc__content img {
    max-width: 100%; }
  @media (max-width: 768px) {
    .textdoc .textdoc__content .btgrid .col-md-6, .textdoc .textdoc__content .btgrid .col-md-4 {
      width: 100%; } }
