.tooltip-trigger {
  width: 24px;
  height: 24px;
  background-color: rgba(163, 203, 238, 0.3);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer; }

.__react_component_tooltip.show {
  max-width: 363px;
  padding: 30px;
  color: #183150;
  font-size: 18px;
  font-weight: 400;
  opacity: 1;
  border: 1px solid #afc3d4;
  border-radius: 0; }
  .__react_component_tooltip.show:before, .__react_component_tooltip.show:after {
    display: none; }
