.profile__content {
  padding: 0;
  position: relative; }
  @media (max-width: 991px) {
    .profile__content {
      padding-top: 50px; } }
  .profile__content .user-info {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .profile__content .user-info .user-name {
      font-family: "DIN Alternate";
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
      margin-right: 30px; }

.profile__header {
  border-bottom: 1px solid #e0e6ec;
  display: flex;
  justify-content: flex-end;
  position: relative; }
  .profile__header a {
    position: relative;
    width: 206px; }
    @media (max-width: 991px) {
      .profile__header a {
        width: 180px; } }
    @media (max-width: 768px) {
      .profile__header a {
        width: 59px; } }
    .profile__header a span {
      display: block; }
      @media (max-width: 767px) {
        .profile__header a span {
          display: none; } }
      .profile__header a span svg {
        margin-left: 15px; }
  .profile__header.mobile {
    display: none; }
    @media (max-width: 991px) {
      .profile__header.mobile {
        position: absolute;
        top: 0;
        z-index: 1;
        width: 100%;
        border: none;
        display: flex;
        align-items: center;
        left: 0; } }
    .profile__header.mobile .user-name {
      font-family: "DIN Alternate";
      color: #fff;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 0;
      margin-right: 30px; }
  @media (max-width: 992px) {
    .profile__header.desktop {
      display: none; } }

.profile__dropdown {
  position: absolute;
  right: 60px;
  top: calc(100% + 4px);
  width: 148px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #b5bfcb;
  background-color: #ffffff;
  display: none;
  animation: fade 0.2s ease-in-out;
  z-index: 2; }
  @media (max-width: 768px) {
    .profile__dropdown {
      right: 14px; } }
  .profile__dropdown.show {
    display: block;
    animation: fade 0.2s ease-in-out; }
  .profile__dropdown ul {
    padding: 18px 13px;
    margin-bottom: 0; }
    .profile__dropdown ul li {
      list-style: none;
      border-bottom: 1px solid transparent;
      color: #183150;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      transition: all 0.2s ease-in-out;
      display: inline-flex;
      cursor: pointer; }
      .profile__dropdown ul li:hover {
        text-decoration: none;
        border-bottom: 1px solid #183150;
        transition: all 0.2s ease-in-out; }
      .profile__dropdown ul li.active {
        opacity: 0.5; }

.profile__body {
  padding: 20px 60px;
  animation: fade 0.2s ease-in-out; }
  @media (max-width: 1199px) {
    .profile__body {
      padding: 20px; } }
  @media (max-width: 991px) {
    .profile__body {
      padding: 15px; } }
  .profile__body .bought-reservations {
    margin-bottom: 60px; }
  .profile__body h2 {
    color: #183150;
    font-size: 40px;
    font-weight: 700;
    line-height: 58px;
    margin-bottom: 36px; }
  .profile__body .pool {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between; }
    .profile__body .pool__visits {
      width: calc(50% - 5px); }
      @media (max-width: 576px) {
        .profile__body .pool__visits {
          width: 100%;
          margin-bottom: 30px; } }
      .profile__body .pool__visits span {
        max-width: 114px;
        border-radius: 3px;
        background-color: #4a96d2;
        color: #ffffff;
        font-family: "DIN Alternate";
        font-size: 14px;
        font-weight: 700;
        padding: 4px 11px;
        display: inline-block;
        margin-bottom: 20px; }
      .profile__body .pool__visits > div {
        border: 1px solid #dae6f6;
        padding: 28px 32px; }
        .profile__body .pool__visits > div .available-visits {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px; }
          .profile__body .pool__visits > div .available-visits h4 {
            color: #183150;
            font-size: 24px;
            font-weight: 600;
            line-height: 26px;
            margin-bottom: 0;
            width: calc(100% - 100px); }
          .profile__body .pool__visits > div .available-visits p {
            width: 80px;
            text-align: right;
            color: #183150;
            font-size: 18px;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 0; }
        .profile__body .pool__visits > div > p {
          color: #183150;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          padding-bottom: 20px;
          margin-bottom: 0;
          border-bottom: 1px dashed #dae6f6; }
        .profile__body .pool__visits > div .btn-wrap {
          display: flex;
          justify-content: center; }
          .profile__body .pool__visits > div .btn-wrap button {
            margin-top: 28px;
            width: 200px;
            height: 44px;
            border-radius: 22px;
            font-size: 16px;
            padding: 0 20px; }
  .profile__body .pool-size {
    max-width: 114px;
    border-radius: 3px;
    background-color: #4a96d2;
    color: #ffffff;
    font-family: "DIN Alternate";
    font-size: 14px;
    font-weight: 700;
    padding: 4px 11px;
    display: inline-block;
    margin-bottom: 20px; }

.profile__selected-times {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px; }
  .profile__selected-times .reserved-time {
    width: calc(33.3333% - 10px);
    border: 1px solid #dae6f6;
    border-top: 4px solid #fe243f;
    margin: 0 15px 15px 0;
    position: relative;
    min-height: 182px; }
    .profile__selected-times .reserved-time:nth-child(3n + 3) {
      margin-right: 0; }
    @media (max-width: 576px) {
      .profile__selected-times .reserved-time {
        width: 100%;
        margin: 0 0 15px 0; } }
    .profile__selected-times .reserved-time .top {
      padding: 20px 45px 20px 18px; }
      .profile__selected-times .reserved-time .top a {
        position: absolute;
        top: 24px;
        right: 18px; }
      .profile__selected-times .reserved-time .top p {
        color: #183150;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0; }
        .profile__selected-times .reserved-time .top p.time {
          color: #183150;
          font-size: 22px;
          font-weight: 600;
          line-height: 26px;
          margin-bottom: 10px; }
    .profile__selected-times .reserved-time .bottom {
      border-top: 1px dashed #dae6f6;
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0; }
      .profile__selected-times .reserved-time .bottom span {
        cursor: pointer;
        color: #183150;
        font-size: 16px;
        font-weight: 400;
        border-bottom: 1px solid #183150;
        margin-bottom: 0;
        transition: all 0.2s ease-in-out; }
      .profile__selected-times .reserved-time .bottom:hover {
        text-decoration: none; }
        .profile__selected-times .reserved-time .bottom:hover span {
          text-decoration: none;
          border-bottom: 1px solid transparent;
          transition: all 0.2s ease-in-out; }
    .profile__selected-times .reserved-time.valid {
      border-top: 4px solid #7ed321;
      min-height: 117px; }
    .profile__selected-times .reserved-time.add-new {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      border: 1px dashed #dae6f6;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-decoration: none;
      cursor: pointer; }
      .profile__selected-times .reserved-time.add-new .add {
        display: flex;
        border-radius: 3px;
        border: 1px dashed #183150;
        opacity: 0.2;
        width: 78px;
        height: 78px;
        justify-content: center;
        align-items: center;
        color: #183150;
        font-size: 58px;
        font-weight: 600;
        margin: 20px 0; }
        .profile__selected-times .reserved-time.add-new .add:hover {
          text-decoration: none; }
      .profile__selected-times .reserved-time.add-new .text {
        color: #183150;
        padding: 0 10px;
        font-size: 16px;
        line-height: 18px;
        transition: all 0.2s ease-in-out; }
      .profile__selected-times .reserved-time.add-new:hover .text {
        opacity: 0.6;
        transition: all 0.2s ease-in-out; }
  .profile__selected-times.empty-profile .add-new {
    width: 100%; }
    .profile__selected-times.empty-profile .add-new .text {
      opacity: 0.5; }

.tabs__wrap {
  border: 1px solid #dae6f6; }

.tabs__navigation {
  border-bottom: 1px solid #dae6f6;
  display: flex; }
  .tabs__navigation p {
    width: 50%;
    border-top: 6px solid transparent;
    transition: all 0.2s ease-in-out;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 2.57px;
    text-transform: uppercase;
    margin-bottom: 0;
    cursor: pointer; }
    .tabs__navigation p.active, .tabs__navigation p:hover {
      border-top: 6px solid #4a96d2;
      transition: all 0.2s ease-in-out;
      text-decoration: none; }

.tabs__content .tab__profile {
  padding: 45px 15px;
  animation: fade 0.2s ease-in-out; }
  .tabs__content .tab__profile form {
    width: 100%;
    max-width: 507px;
    margin: 0 auto; }
    .tabs__content .tab__profile form .form-group {
      margin-bottom: 36px; }
    .tabs__content .tab__profile form .btn-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 576px) {
        .tabs__content .tab__profile form .btn-wrap {
          flex-direction: column; }
          .tabs__content .tab__profile form .btn-wrap .btn {
            margin-bottom: 30px; } }
      .tabs__content .tab__profile form .btn-wrap p {
        color: #183150;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        border-bottom: 1px solid #183150;
        cursor: pointer;
        transition: all 0.2s ease-in-out; }
        .tabs__content .tab__profile form .btn-wrap p:hover {
          text-decoration: none;
          border-bottom: 1px solid transparent;
          transition: all 0.2s ease-in-out; }

.tabs__content .tab__password {
  padding: 45px 15px;
  animation: fade 0.2s ease-in-out; }
  .tabs__content .tab__password form {
    width: 100%;
    max-width: 507px;
    margin: 0 auto; }
    .tabs__content .tab__password form .form-group {
      margin-bottom: 36px; }
    .tabs__content .tab__password form .btn-wrap {
      display: flex;
      justify-content: center; }

.modal__delete {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(22, 68, 124, 0.3);
  width: 100%;
  height: 100vh;
  z-index: 10;
  padding: 15px;
  animation: fade 0.2s ease-in-out; }

.modal__content {
  width: 100%;
  max-width: 583px;
  min-height: 317px;
  margin: 20vh auto 0;
  border: 1px solid #dae6f6;
  background-color: #ffffff;
  padding: 55px; }
  .modal__content h4 {
    color: #183150;
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 20px; }
  .modal__content p {
    color: #183150;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 30px; }
  .modal__content .btn-wrap {
    display: flex;
    justify-content: center; }
    .modal__content .btn-wrap button {
      max-width: 158px;
      margin: 0 11px; }
