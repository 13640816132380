.cookies {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0 0;
  color: #fff;
  background: rgba(0, 0, 0, 0.8); }
  .cookies h6 {
    font-size: 18px;
    font-weight: 700; }
  .cookies p {
    line-height: 1.3; }
  .cookies button {
    float: right; }
  .cookies.cookies--hide {
    display: none; }
