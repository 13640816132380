.about {
  padding-top: 47px;
  padding-bottom: 80px; }
  @media (max-width: 768px) {
    .about {
      padding-top: 30px;
      padding-bottom: 0; } }
  .about h1 {
    color: #16447c;
    font-family: "DIN Alternate";
    font-size: 48px;
    font-weight: 700;
    line-height: 79px;
    margin: 0; }
  .about .about__head {
    padding-bottom: 60px; }
    @media (max-width: 992px) {
      .about .about__head {
        padding-bottom: 20px; } }
    .about .about__head p {
      color: #183150;
      font-size: 18px;
      line-height: 26px; }
      .about .about__head p.strong {
        color: #16447c;
        font-family: "DIN Alternate";
        font-size: 21px;
        font-weight: 700;
        line-height: 28px; }
    .about .about__head .schedule_link {
      border-top: 1px solid rgba(24, 49, 80, 0.2);
      border-bottom: 1px solid rgba(24, 49, 80, 0.2);
      background: url("../../assets/img/arrow-right.svg") no-repeat right; }
      .about .about__head .schedule_link h5 {
        color: #16447c;
        font-family: "urw-din";
        font-size: 21px;
        font-weight: 500;
        margin: 0 0 5px; }
      .about .about__head .schedule_link a {
        display: block;
        padding-top: 19px;
        padding-right: 50px; }
        .about .about__head .schedule_link a:hover {
          text-decoration: none; }
  .about .about__topics a:hover {
    text-decoration: none; }
  .about .about__topics .row {
    margin-left: -8px;
    margin-right: -8px; }
    .about .about__topics .row .col-12 {
      padding-left: 8px;
      padding-right: 8px; }
  .about .about__topics .topic {
    background: #175aa8;
    padding-bottom: 20px; }
    @media (max-width: 1200px) {
      .about .about__topics .topic {
        margin-bottom: 20px; } }
    .about .about__topics .topic img {
      display: block;
      max-width: 100%;
      margin: 0 auto 20px; }
    .about .about__topics .topic h6 {
      font-family: "urw-din";
      color: #fff;
      font-size: 22px;
      font-weight: 500;
      clear: left;
      margin: 0 0 5px; }
      @media (min-width: 768px) and (max-width: 1200px) {
        .about .about__topics .topic h6 {
          font-size: 18px; } }
    .about .about__topics .topic span, .about .about__topics .topic h6 {
      margin-left: 20px; }
    .about .about__topics .topic span.type {
      display: block;
      border-radius: 13px;
      background-color: #fe243f;
      padding: 4px 12px 5px;
      float: left;
      color: #ffffff;
      font-family: "DIN Alternate";
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 17px; }
    .about .about__topics .topic span.more {
      color: #fff;
      font-size: 18px; }
