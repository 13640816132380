.btn {
  max-width: 247px;
  width: 100%;
  height: 60px;
  border-radius: 30px;
  background-color: #fe243f;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 19px;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  padding: 0 15px; }
  .btn:hover {
    text-decoration: none;
    color: #fff;
    background-color: #ee011e;
    transition: all 0.2s ease-in-out; }
  .btn:focus {
    box-shadow: none; }

.btn-transparent {
  max-width: 247px;
  width: 100%;
  height: 60px;
  border-radius: 30px;
  background: #fff;
  border: 1px solid #fe243f;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fe243f;
  font-size: 19px;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  padding: 0 15px;
  cursor: pointer; }
  .btn-transparent:hover {
    text-decoration: none;
    color: #ee011e;
    border-color: #ee011e;
    transition: all 0.2s ease-in-out; }
  .btn-transparent:focus {
    outline: none; }
  .btn-transparent:disabled {
    opacity: 0.6; }

.profile--link {
  position: absolute;
  top: 0;
  right: 0;
  width: 208px;
  height: 80px;
  background-color: #fe243f;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 2.57px;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  padding: 0 40px; }
  .profile--link:hover {
    text-decoration: none;
    color: #fff;
    background-color: #ee011e;
    transition: all 0.2s ease-in-out; }
  .profile--link > svg {
    height: 22px; }
  @media (max-width: 991px) {
    .profile--link {
      padding: 0 2vw;
      width: 160px; } }
  @media (max-width: 768px) {
    .profile--link {
      height: 59px;
      width: 59px;
      display: flex;
      justify-content: center; }
      .profile--link span {
        display: none; } }
  @media (min-width: 992px) {
    .profile--link.inner {
      padding: 0 27px; } }
