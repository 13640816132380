@-webkit-keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }
