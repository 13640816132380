.sidebar-arrow {
  padding-top: 50px;
  position: absolute;
  z-index: 10;
  top: 34px;
  left: 64px; }
  @media (max-width: 576px) {
    .sidebar-arrow {
      left: 30px; } }
  .sidebar-arrow a, .sidebar-arrow .back-arrow {
    position: absolute;
    top: 0;
    display: block;
    width: 28px;
    height: 17px;
    margin-bottom: 40px;
    background-image: url("../assets/img/back-arrow.svg");
    cursor: pointer;
    transition: all 0.2s ease-in-out; }
    .sidebar-arrow a:hover, .sidebar-arrow .back-arrow:hover {
      opacity: 0.6;
      transition: all 0.2s ease-in-out; }

.sidebar-wrap {
  position: relative;
  width: 100%;
  padding: 0;
  background-color: #16447c;
  overflow: hidden; }
  .sidebar-wrap__admin {
    height: 100%;
    background-color: #175aa8; }

.sidebar {
  min-height: 100vh;
  background-color: #16447c;
  padding: 94px 64px 34px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden; }
  @media (max-width: 991px) {
    .sidebar {
      min-height: auto; } }
  @media (max-width: 576px) {
    .sidebar {
      padding: 94px 30px 34px; } }
  .sidebar .absolute-bg {
    position: absolute;
    width: 110%;
    left: -5%;
    bottom: -5%; }
    .sidebar .absolute-bg .img {
      width: 110%;
      left: 0;
      bottom: 0;
      top: auto;
      position: absolute;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover; }
  .sidebar .top h1 {
    color: #ffffff;
    font-family: "DIN Alternate";
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    margin-bottom: 28px; }
    @media (max-width: 1150px) {
      .sidebar .top h1 {
        font-size: 40px;
        line-height: 50px; } }
    @media (max-width: 576px) {
      .sidebar .top h1 {
        font-size: 38px;
        line-height: 48px; } }
  .sidebar .top p {
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px; }
    @media (max-width: 576px) {
      .sidebar .top p {
        font-size: 16px;
        line-height: 26px; } }
  .sidebar .top .calendar-links {
    margin-top: -30px;
    margin-bottom: 50px;
    display: flex;
    align-items: flex-start;
    flex-direction: column; }
    .sidebar .top .calendar-links p {
      cursor: pointer;
      display: inline-block;
      border-bottom: 1px solid transparent;
      transition: all 0.2s ease-in-out; }
      .sidebar .top .calendar-links p:hover {
        border-bottom: 1px solid #fff;
        transition: all 0.2s ease-in-out; }
  .sidebar .top .profile__info {
    background-color: #fff;
    max-width: 320px; }
    .sidebar .top .profile__info-top {
      padding: 20px 20px 10px 20px; }
    .sidebar .top .profile__info-bottom {
      padding: 15px 20px;
      border: 1px dashed #dae6f6; }
      .sidebar .top .profile__info-bottom .btn-wrap {
        display: flex;
        justify-content: center; }
        .sidebar .top .profile__info-bottom .btn-wrap .btn {
          height: 46px;
          font-size: 16px;
          padding: 0 20px;
          max-width: 100%;
          width: auto; }
    .sidebar .top .profile__info h2 {
      font-family: "DIN Alternate";
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 20px; }
    .sidebar .top .profile__info .qr-code img {
      display: block;
      max-width: 120px;
      margin-bottom: 5px; }
    .sidebar .top .profile__info .qr-code p {
      color: #183150;
      font-size: 14px; }
    .sidebar .top .profile__info .pool__visits {
      display: flex;
      max-width: 190px;
      justify-content: space-between;
      margin-bottom: 10px; }
      .sidebar .top .profile__info .pool__visits span {
        color: #183150; }
  .sidebar .to-home {
    position: absolute;
    top: 35px;
    right: 50px;
    z-index: 10;
    color: #ffffff; }
    @media (max-width: 576px) {
      .sidebar .to-home {
        right: 15px; } }
    .sidebar .to-home p {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
      cursor: pointer;
      border-bottom: 1px solid transparent;
      transition: border-color 0.2s ease-in-out; }
      .sidebar .to-home p:hover {
        border-color: #fff;
        transition: border-bottom 0.2s ease-in-out; }
  .sidebar .session {
    position: absolute;
    top: 35px;
    right: 50px;
    z-index: 10; }
    @media (max-width: 576px) {
      .sidebar .session {
        right: 15px; } }
    .sidebar .session p {
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0; }
      .sidebar .session p.session-time {
        color: #ffffff;
        font-family: "DIN Alternate";
        font-size: 28px;
        font-weight: 400;
        line-height: 48px;
        padding-left: 45px;
        background-image: url("../assets/img/session-time.svg");
        background-position: center left 5px;
        background-repeat: no-repeat; }
  .sidebar .bottom {
    width: 100%; }
    .sidebar .bottom p {
      color: #ffffff;
      font-family: "DIN Alternate";
      font-size: 24px;
      font-weight: 700;
      line-height: 32px; }
    .sidebar .bottom ul {
      padding: 0; }
      .sidebar .bottom ul li {
        list-style: none;
        width: 100%;
        background-color: #fff;
        padding: 20px 20px 20px 50px;
        color: #183150;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        position: relative; }
        .sidebar .bottom ul li::first-letter {
          text-transform: capitalize; }
        .sidebar .bottom ul li:before {
          content: url(../assets/img/check-red.svg);
          position: absolute;
          left: 20px; }
        .sidebar .bottom ul li:after {
          content: '';
          width: calc(100% - 40px);
          height: 1px;
          background-color: #dae6f6;
          position: absolute;
          bottom: 0;
          left: 20px; }
        .sidebar .bottom ul li:first-child {
          border-top: 4px solid #fe243f; }
        .sidebar .bottom ul li:last-child:after {
          display: none; }
    .sidebar .bottom span {
      display: block;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px; }
      .sidebar .bottom span a {
        text-decoration: none;
        border-bottom: 1px solid #fff;
        color: #fff;
        transition: all 0.2s ease-in-out; }
        .sidebar .bottom span a:hover {
          text-decoration: none;
          border-bottom: 1px solid transparent;
          transition: all 0.2s ease-in-out; }
  .sidebar__profile {
    padding-bottom: 150px; }
    .sidebar__profile .top {
      margin-bottom: 16px; }
    .sidebar__profile .inner-link {
      margin-bottom: 76px; }
      .sidebar__profile .inner-link p {
        display: flex;
        align-items: center;
        color: #ffffff;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-decoration: underline;
        cursor: pointer; }
        .sidebar__profile .inner-link p svg {
          margin-right: 20px;
          height: 20px; }
    .sidebar__profile .bottom {
      position: absolute;
      bottom: 34px;
      width: calc(100% - 128px); }
      @media (min-width: 577px) {
        .sidebar__profile .bottom {
          left: 64px; } }
  .sidebar__admin {
    background-color: #175aa8; }
    @media (min-width: 577px) {
      .sidebar__admin {
        padding: 94px 45px 34px; } }
    .sidebar__admin .top h1 {
      margin-bottom: 116px; }
    .sidebar__admin span {
      max-width: 114px;
      border-radius: 3px;
      background-color: #4a96d2;
      color: #ffffff;
      font-family: "DIN Alternate";
      font-size: 14px;
      font-weight: 700;
      padding: 4px 11px;
      display: inline-block;
      margin-bottom: 20px; }
    .sidebar__admin .pool {
      display: flex;
      flex-wrap: wrap;
      max-width: 474px;
      margin-bottom: 35px;
      border-bottom: 1px solid rgba(74, 150, 210, 0.3);
      padding-bottom: 10px; }
      .sidebar__admin .pool:last-child {
        border: none; }
      .sidebar__admin .pool .available-visits {
        width: 100%;
        display: flex;
        justify-content: space-between; }
        .sidebar__admin .pool .available-visits h4 {
          color: #ffffff;
          font-size: 24px;
          font-weight: 600;
          line-height: 26px; }
        .sidebar__admin .pool .available-visits p {
          font-size: 16px;
          font-weight: 400;
          line-height: 26px; }
          .sidebar__admin .pool .available-visits p.larger {
            font-size: 18px; }
