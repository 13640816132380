.home_link {
  float: left;
  width: 80px;
  height: 81px;
  border-right: 1px solid #dae6f6;
  border-bottom: 1px solid #dae6f6;
  background: url("../../assets/img/ico-home.svg") no-repeat;
  background-position: center; }
  .home_link a {
    display: block;
    width: 80px;
    height: 80px; }
  @media (max-width: 768px) {
    .home_link {
      display: none; } }

.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 80px);
  z-index: 2; }
  .menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden; }
    .menu ul li {
      float: left;
      text-align: center;
      margin: 0 50px; }
      @media (max-width: 1024px) {
        .menu ul li {
          margin: 0 15px; } }
      .menu ul li a {
        height: 80px;
        line-height: 80px;
        display: block;
        color: #fff;
        font-family: 'Avenir';
        font-size: 12px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 2.57px;
        transition: all 0.2s ease-in-out; }
        .menu ul li a:hover {
          text-decoration: none;
          color: #cccccc;
          transition: all 0.2s ease-in-out; }
  .menu.menu--inside {
    position: relative;
    border-bottom: 1px solid #dae6f6;
    margin-left: 80px; }
    .menu.menu--inside ul li a {
      color: #183150; }
      .menu.menu--inside ul li a.active {
        position: relative; }
        .menu.menu--inside ul li a.active:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 6px;
          background: #4a96d2; }
  @media (max-width: 768px) {
    .menu {
      display: none; } }

.menu--transparent .home_link {
  background: url("../../assets/img/ico-home-white.svg") no-repeat;
  background-position: center;
  position: relative;
  z-index: 10; }

.menu--transparent .menu {
  background: #16447c;
  margin-left: 0; }

.menu--transparent .menu.menu--inside ul li a {
  color: #fff; }

.mobile_menu {
  display: none;
  height: 59px;
  width: 100%;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  .mobile_menu .burger-tap {
    position: absolute;
    z-index: 5;
    top: 10px;
    left: 10px;
    width: 52px;
    height: 42px; }
    .mobile_menu .burger-tap .burger {
      width: 20px;
      height: 20px;
      position: relative;
      transform: rotate(0deg);
      transition: .5s ease-in-out;
      cursor: pointer;
      margin: 13px 0 15px 16px; }
      .mobile_menu .burger-tap .burger:focus {
        outline: none; }
      .mobile_menu .burger-tap .burger span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #fff;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out; }
        .mobile_menu .burger-tap .burger span:nth-child(1) {
          top: 0px; }
        .mobile_menu .burger-tap .burger span:nth-child(2), .mobile_menu .burger-tap .burger span:nth-child(3) {
          top: 6px; }
        .mobile_menu .burger-tap .burger span:nth-child(4) {
          top: 12px; }
      .mobile_menu .burger-tap .burger.open span:nth-child(1) {
        top: 7px;
        width: 0%;
        left: 50%; }
      .mobile_menu .burger-tap .burger.open span:nth-child(2) {
        transform: rotate(45deg); }
      .mobile_menu .burger-tap .burger.open span:nth-child(3) {
        transform: rotate(-45deg); }
      .mobile_menu .burger-tap .burger.open span:nth-child(4) {
        top: 7px;
        width: 0%;
        left: 50%; }
  .mobile_menu.menu--inside {
    position: relative;
    border-bottom: 1px solid #dae6f6; }
    .mobile_menu.menu--inside .burger-tap span {
      background: #183150; }
  @media (max-width: 768px) {
    .mobile_menu {
      display: block; } }
  .mobile_menu.open {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
    .mobile_menu.open .burger-tap span {
      background: #fff; }

.mobile_menu_links {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100vh;
  background: #175aa8;
  padding-top: 60px;
  transform: translateX(-100%);
  transition: all 0.2s ease-in-out; }
  .mobile_menu_links.open {
    transform: translateX(0%); }
  .mobile_menu_links ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .mobile_menu_links ul li a {
      display: block;
      color: #fff;
      padding: 30px 28px;
      font-family: "Avenir";
      font-size: 12px;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 2.57px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
