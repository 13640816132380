.admin-panels {
  padding-top: 70px;
  padding-bottom: 70px; }
  .admin-panels__menu {
    display: flex;
    border-bottom: 1px solid #f6f9fd;
    height: 80px; }
    @media (max-width: 767px) {
      .admin-panels__menu {
        height: 59px; } }
  .admin-panels h1 {
    color: #16447c;
    font-family: "DIN Alternate";
    font-size: 75px;
    font-weight: 700;
    line-height: 79px; }
    @media (max-width: 991px) {
      .admin-panels h1 {
        font-size: 55px;
        line-height: 59px;
        padding: 0 15px; } }
    @media (max-width: 576px) {
      .admin-panels h1 {
        font-size: 35px;
        line-height: 39px; } }
  .admin-panels p.subtitle {
    color: #16447c;
    font-family: "DIN Alternate";
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 60px; }
    @media (max-width: 991px) {
      .admin-panels p.subtitle {
        padding: 0 15px; } }
    @media (max-width: 576px) {
      .admin-panels p.subtitle {
        font-size: 18px;
        line-height: 28px; } }
  .admin-panels .panel {
    padding: 0 30px; }
    @media (max-width: 991px) {
      .admin-panels .panel {
        margin-bottom: 30px;
        padding: 0 15px; } }
    .admin-panels .panel .link {
      background-color: #175aa8;
      padding: 80px 20px 20px;
      height: 100%;
      position: relative;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .admin-panels .panel .link:hover {
        background-color: #16447c;
        transition: all 0.2s ease-in-out; }
      .admin-panels .panel .link .img-wrap {
        height: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 60px; }
      .admin-panels .panel .link h6 {
        color: #ffffff;
        font-family: "DIN Alternate";
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 60px; }
      .admin-panels .panel .link p {
        color: #ffffff;
        font-size: 18px;
        font-weight: 400;
        position: absolute;
        bottom: 20px;
        left: 20px;
        margin-bottom: 0; }

.admin-dashboard {
  position: relative; }
  .admin-dashboard .admin-menu {
    display: flex;
    border-bottom: 1px solid #f6f9fd;
    height: 80px;
    position: absolute;
    left: -15px;
    width: 100%; }
    @media (max-width: 767px) {
      .admin-dashboard .admin-menu {
        height: 59px; } }
    .admin-dashboard .admin-menu.mobile {
      display: none; }
      @media (max-width: 991px) {
        .admin-dashboard .admin-menu.mobile {
          display: flex;
          left: 0; } }
    @media (max-width: 992px) {
      .admin-dashboard .admin-menu.desktop {
        display: none; } }
  .admin-dashboard .admin-content {
    margin-top: 80px;
    padding: 34px 64px 34px 34px;
    animation: fade 0.2s ease-in-out; }
    @media (max-width: 767px) {
      .admin-dashboard .admin-content {
        padding: 15px 15px 90px; } }
    .admin-dashboard .admin-content h2 {
      color: #183150;
      font-family: "DIN Alternate";
      font-size: 40px;
      font-weight: 700;
      line-height: 58px; }
    .admin-dashboard .admin-content p {
      color: #183150;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      margin-bottom: 40px; }
    .admin-dashboard .admin-content .calendar-navi p, .admin-dashboard .admin-content .usage-description p {
      margin-bottom: 0; }
    .admin-dashboard .admin-content form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%; }
      @media (max-width: 767px) {
        .admin-dashboard .admin-content form {
          margin-bottom: 50px; } }
      .admin-dashboard .admin-content form .form-group {
        width: calc(100% - 300px); }
        @media (max-width: 767px) {
          .admin-dashboard .admin-content form .form-group {
            width: 100%; } }
        .admin-dashboard .admin-content form .form-group .form-control:focus {
          border: 1px solid #183150; }
        .admin-dashboard .admin-content form .form-group svg path {
          fill: transparent;
          fill-opacity: 1; }
      .admin-dashboard .admin-content form .loader {
        position: absolute;
        top: 15px;
        right: 15px;
        animation: rotating 0.5s linear infinite; }
      .admin-dashboard .admin-content form > .btn:hover {
        background-color: transparent; }
  .admin-dashboard .pool-availability {
    margin-bottom: 64px; }
    .admin-dashboard .pool-availability .availability__time {
      display: flex;
      align-items: center; }
      .admin-dashboard .pool-availability .availability__time svg {
        margin-right: 15px; }
    .admin-dashboard .pool-availability .pool {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between; }
      .admin-dashboard .pool-availability .pool__visits {
        width: calc(50% - 5px); }
        @media (max-width: 576px) {
          .admin-dashboard .pool-availability .pool__visits {
            width: 100%;
            margin-bottom: 30px; } }
        .admin-dashboard .pool-availability .pool__visits > span {
          max-width: 114px;
          border-radius: 3px;
          background-color: #4a96d2;
          color: #ffffff;
          font-family: "DIN Alternate";
          font-size: 14px;
          font-weight: 700;
          padding: 4px 11px;
          display: inline-block;
          margin-bottom: 20px; }
        .admin-dashboard .pool-availability .pool__visits > div {
          border: 1px solid #dae6f6;
          padding: 28px 32px; }
          .admin-dashboard .pool-availability .pool__visits > div .available-visits {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px; }
            .admin-dashboard .pool-availability .pool__visits > div .available-visits h4 {
              color: #183150;
              font-size: 24px;
              font-weight: 600;
              line-height: 26px;
              margin-bottom: 0;
              width: calc(100% - 100px); }
            .admin-dashboard .pool-availability .pool__visits > div .available-visits p {
              color: #183150;
              font-size: 18px;
              font-weight: 400;
              line-height: 26px;
              margin-bottom: 0px; }
          .admin-dashboard .pool-availability .pool__visits > div form.register {
            width: 100%;
            display: flex;
            flex-direction: column;
            border-top: 1px dashed #dae6f6;
            padding: 20px 0 0; }
            .admin-dashboard .pool-availability .pool__visits > div form.register input[type=radio] {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0; }
              .admin-dashboard .pool-availability .pool__visits > div form.register input[type=radio]:checked ~ span:before {
                opacity: 1; }
            .admin-dashboard .pool-availability .pool__visits > div form.register ul {
              margin-bottom: 0; }
              .admin-dashboard .pool-availability .pool__visits > div form.register ul li {
                margin-bottom: 10px; }
                .admin-dashboard .pool-availability .pool__visits > div form.register ul li:last-child {
                  margin-bottom: 15px; }
            .admin-dashboard .pool-availability .pool__visits > div form.register .btn-wrap {
              display: flex;
              justify-content: center;
              border-top: 1px dashed #dae6f6; }
              .admin-dashboard .pool-availability .pool__visits > div form.register .btn-wrap button {
                margin-top: 28px;
                width: 200px;
                height: 44px;
                border-radius: 22px;
                font-size: 16px;
                padding: 0 20px; }
  .admin-dashboard .book-visit {
    margin-bottom: 100px; }
    .admin-dashboard .book-visit h2 {
      margin-bottom: 40px; }
    .admin-dashboard .book-visit .btn-wrap {
      display: flex;
      justify-content: space-between; }
      @media (max-width: 767px) {
        .admin-dashboard .book-visit .btn-wrap {
          flex-direction: column;
          align-items: center; } }
      @media (max-width: 767px) {
        .admin-dashboard .book-visit .btn-wrap .btn {
          width: 100%;
          margin-bottom: 15px; } }
  .admin-dashboard .reserved-visits {
    padding: 64px 0; }
    .admin-dashboard .reserved-visits h2 {
      margin-bottom: 40px; }
    .admin-dashboard .reserved-visits span {
      max-width: 114px;
      border-radius: 3px;
      background-color: #4a96d2;
      color: #ffffff;
      font-family: "DIN Alternate";
      font-size: 14px;
      font-weight: 700;
      padding: 4px 11px;
      display: inline-block;
      margin-bottom: 20px; }
    .admin-dashboard .reserved-visits .visits {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 50px; }
      .admin-dashboard .reserved-visits .visits .item {
        width: calc(33.3333% - 10px);
        border: 1px solid #dae6f6;
        border-top: 4px solid #fe243f;
        margin: 0 15px 15px 0;
        position: relative;
        padding: 20px; }
        .admin-dashboard .reserved-visits .visits .item.valid {
          border-color: #7ed321; }
        .admin-dashboard .reserved-visits .visits .item:nth-child(3n + 3) {
          margin-right: 0; }
        @media (max-width: 576px) {
          .admin-dashboard .reserved-visits .visits .item {
            width: 100%;
            margin: 0 0 15px 0; } }
        .admin-dashboard .reserved-visits .visits .item h4 {
          color: #183150;
          font-size: 24px;
          font-weight: 600;
          line-height: 26px; }
        .admin-dashboard .reserved-visits .visits .item p {
          color: #183150;
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
          margin-bottom: 0; }
        .admin-dashboard .reserved-visits .visits .item .btns {
          display: flex;
          align-items: center;
          justify-content: center;
          border-top: 1px dashed #dae6f6;
          margin-top: 15px;
          padding-top: 15px; }
          .admin-dashboard .reserved-visits .visits .item .btns button {
            height: 44px;
            border-radius: 22px;
            font-size: 16px;
            padding: 0 20px;
            margin-right: 10px; }
          .admin-dashboard .reserved-visits .visits .item .btns p {
            margin-bottom: 0;
            cursor: pointer; }
  .admin-dashboard .result {
    width: 100%;
    max-width: 596px;
    border: 1px solid #dae6f6;
    margin-top: 50px;
    margin-bottom: 30px; }
    @media (max-width: 767px) {
      .admin-dashboard .result {
        max-width: 100%; } }
    .admin-dashboard .result .header {
      position: relative;
      height: 56px;
      display: flex;
      align-items: center; }
      .admin-dashboard .result .header p {
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        padding: 0 30px; }
      .admin-dashboard .result .header img {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%); }
    .admin-dashboard .result .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      margin: 0 30px;
      border-bottom: 1px solid #dae6f6; }
      .admin-dashboard .result .item:last-child {
        border-bottom: none; }
      .admin-dashboard .result .item p {
        color: #183150;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0; }
        .admin-dashboard .result .item p.price {
          font-size: 25px;
          font-weight: 600;
          line-height: 26px;
          text-transform: uppercase; }
      .admin-dashboard .result .item p.link {
        color: #183150;
        font-size: 18px;
        font-weight: 400;
        line-height: 23px;
        border-bottom: 1px solid #183150;
        transition: all 0.2s ease-in-out;
        position: relative;
        margin-right: 30px;
        text-decoration: none;
        cursor: pointer; }
        .admin-dashboard .result .item p.link:hover {
          border-bottom: 1px solid transparent;
          transition: all 0.2s ease-in-out; }
        .admin-dashboard .result .item p.link img {
          position: absolute;
          right: -30px;
          top: 5px; }
      .admin-dashboard .result .item span {
        color: rgba(24, 49, 80, 0.6);
        font-size: 13px; }
      .admin-dashboard .result .item div:first-child p {
        font-weight: 600; }
      .admin-dashboard .result .item div:last-child {
        display: flex;
        align-items: center; }
        .admin-dashboard .result .item div:last-child b {
          font-weight: normal;
          padding: 0 5px; }
        .admin-dashboard .result .item div:last-child p:last-child {
          font-size: 14px;
          cursor: pointer; }
      .admin-dashboard .result .item.featured {
        height: 80px;
        position: relative; }
        .admin-dashboard .result .item.featured:before, .admin-dashboard .result .item.featured:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          margin-top: -5px;
          width: 10px;
          height: 20px;
          background: #fff; }
        .admin-dashboard .result .item.featured:before {
          border-right: 1px solid #dae6f6;
          left: -31px;
          border-bottom-right-radius: 40px;
          border-top-right-radius: 40px; }
        .admin-dashboard .result .item.featured:after {
          border-left: 1px solid #dae6f6;
          right: -31px;
          border-bottom-left-radius: 40px;
          border-top-left-radius: 40px; }
        .admin-dashboard .result .item.featured div:first-child p {
          font-size: 24px; }
    .admin-dashboard .result__ok .header {
      background-color: #7ed321; }
    .admin-dashboard .result__ok .item .selected-time {
      color: #7ed321; }
      .admin-dashboard .result__ok .item .selected-time span {
        color: #183150;
        font-size: 18px; }
    .admin-dashboard .result__ok.btns {
      width: 100%;
      max-width: 596px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 767px) {
        .admin-dashboard .result__ok.btns {
          max-width: 100%; } }
      .admin-dashboard .result__ok.btns .btn-red {
        margin-right: 20px; }
      @media (max-width: 500px) {
        .admin-dashboard .result__ok.btns {
          flex-wrap: wrap;
          justify-content: center; }
          .admin-dashboard .result__ok.btns .btn-red {
            margin: 0 0 20px; } }
      .admin-dashboard .result__ok.btns p {
        color: #183150;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        border-bottom: 1px solid #183150;
        margin-bottom: 0;
        transition: all 0.2s ease-in-out;
        margin-left: 50px;
        cursor: pointer; }
        .admin-dashboard .result__ok.btns p:hover {
          border-bottom: 1px solid transparent;
          transition: all 0.2s ease-in-out; }
    .admin-dashboard .result__thnx {
      border: none;
      border-top: 4px solid #7ed321;
      padding: 17px 20px; }
      .admin-dashboard .result__thnx p {
        color: #183150;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 20px; }
        .admin-dashboard .result__thnx p img {
          margin-right: 17px; }
      .admin-dashboard .result__thnx p.date {
        padding-left: 30px;
        margin-bottom: 50px; }
      .admin-dashboard .result__thnx button {
        margin-left: -20px; }
    .admin-dashboard .result__empty .header {
      background-color: #fe243f; }
    .admin-dashboard .result__empty.btns {
      width: 100%;
      max-width: 596px;
      display: flex;
      justify-content: center; }
      @media (max-width: 767px) {
        .admin-dashboard .result__empty.btns {
          max-width: 100%; } }
    .admin-dashboard .result__empty.with_space {
      justify-content: space-between; }
      @media (max-width: 576px) {
        .admin-dashboard .result__empty.with_space {
          flex-wrap: wrap;
          justify-content: center; }
          .admin-dashboard .result__empty.with_space button {
            margin-bottom: 20px; } }
    .admin-dashboard .result__wrong-time .header {
      background-color: #f5a623; }
    .admin-dashboard .result__wrong-time .item p.selected-time {
      color: #f5a623; }
    .admin-dashboard .result__wrong-time.btns {
      width: 100%;
      max-width: 596px;
      display: flex;
      justify-content: center; }
      @media (max-width: 767px) {
        .admin-dashboard .result__wrong-time.btns {
          max-width: 100%; } }
    .admin-dashboard .result__ticket-late .header {
      background-color: #fe243f; }
    .admin-dashboard .result__ticket-late .item p.selected-time {
      color: #fe243f; }
    .admin-dashboard .result__ticket-late.btns {
      width: 100%;
      max-width: 596px;
      display: flex;
      justify-content: center; }
      @media (max-width: 767px) {
        .admin-dashboard .result__ticket-late.btns {
          max-width: 100%; } }
  .admin-dashboard .modal__void {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(22, 68, 124, 0.3);
    width: 100%;
    height: 100vh;
    z-index: 10;
    padding: 15px;
    animation: fade 0.2s ease-in-out; }
  @media (max-width: 576px) {
    .admin-dashboard .modal__content {
      padding: 55px 15px; } }
  .admin-dashboard .modal__content form {
    flex-wrap: wrap; }
  .admin-dashboard .modal__content .btn-wrap {
    display: flex;
    justify-content: left;
    width: 100%; }
    .admin-dashboard .modal__content .btn-wrap button {
      max-width: 158px;
      margin: 0; }
      .admin-dashboard .modal__content .btn-wrap button:first-child {
        margin: 0 22px 0 0; }

ul.radio_btns {
  padding: 0; }
  ul.radio_btns li {
    list-style: none;
    color: #183150;
    font-size: 18px;
    font-weight: 400;
    padding-left: 30px;
    position: relative; }
    ul.radio_btns li label span {
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #b6c2d0;
      background-color: #ffffff;
      border-radius: 50%; }
      ul.radio_btns li label span:before {
        content: '';
        position: absolute;
        left: 3px;
        top: 3px;
        width: 10px;
        height: 10px;
        background-color: #fe243f;
        border-radius: 50%;
        opacity: 0; }
    ul.radio_btns li input[type=radio] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0; }
      ul.radio_btns li input[type=radio]:checked ~ span:before {
        opacity: 1; }
