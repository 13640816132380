.contacts {
  padding-top: 47px;
  padding-bottom: 80px; }
  @media (max-width: 768px) {
    .contacts {
      padding-top: 30px;
      padding-bottom: 20px; } }
  .contacts h1 {
    color: #16447c;
    font-family: "DIN Alternate";
    font-size: 48px;
    font-weight: 700;
    line-height: 79px;
    margin: 0 0 31px; }
  @media (min-width: 768px) {
    .contacts .col--map {
      padding-right: 0; }
    .contacts .col--details {
      padding-left: 0; } }
  .contacts .contacts__details {
    height: 100%;
    padding: 33px 33px 0 33px;
    border: 1px solid #dae6f6; }
    @media (max-width: 768px) {
      .contacts .contacts__details {
        padding: 33px 20px 10px; } }
    .contacts .contacts__details h2 {
      color: #183150;
      font-family: "urw-din";
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin: 0 0 34px; }
    .contacts .contacts__details .details_item {
      margin: 20px 0 0;
      border-bottom: 1px solid #dae6f6; }
      .contacts .contacts__details .details_item span {
        opacity: 0.4;
        color: #183150;
        font-family: "Avenir";
        font-size: 12px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 2.57px; }
      .contacts .contacts__details .details_item p {
        color: #183150;
        font-size: 18px;
        line-height: 30px;
        padding: 0 0 10px;
        margin: 0; }
      .contacts .contacts__details .details_item .working_hours {
        overflow: hidden;
        padding-top: 9px; }
        .contacts .contacts__details .details_item .working_hours .days {
          float: left; }
          .contacts .contacts__details .details_item .working_hours .days ul {
            list-style: none;
            margin: 0;
            padding: 0; }
            .contacts .contacts__details .details_item .working_hours .days ul li {
              display: inline-block;
              width: 39px;
              height: 39px;
              line-height: 39px;
              background: #16447c;
              text-align: center;
              color: #fff;
              text-transform: uppercase;
              margin-right: 8px; }
              @media (max-width: 374px) {
                .contacts .contacts__details .details_item .working_hours .days ul li {
                  width: 30px;
                  height: 30px;
                  line-height: 30px;
                  margin-right: 5px; } }
        .contacts .contacts__details .details_item .working_hours .time {
          float: right;
          text-align: right;
          color: #183150;
          font-size: 18px;
          line-height: 39px; }
          @media (max-width: 374px) {
            .contacts .contacts__details .details_item .working_hours .time {
              line-height: 30px; } }
      .contacts .contacts__details .details_item.details_item--hours {
        border: none; }
        .contacts .contacts__details .details_item.details_item--hours span {
          display: block;
          padding-bottom: 6px; }
    .contacts .contacts__details .note {
      padding-top: 28px;
      opacity: 0.7;
      color: #16447c;
      font-size: 15px; }

.map {
  height: 520px;
  background: #333; }
