.prices {
  padding-top: 47px;
  padding-bottom: 80px; }
  @media (max-width: 768px) {
    .prices {
      padding-top: 30px;
      padding-bottom: 20px; } }
  .prices h1 {
    color: #16447c;
    font-family: "DIN Alternate";
    font-size: 48px;
    font-weight: 700;
    line-height: 79px;
    margin: 0 0 31px; }
  .prices .prices__plans {
    margin-bottom: 19px; }
    @media (max-width: 768px) {
      .prices .prices__plans {
        margin-bottom: 0; } }
    .prices .prices__plans .plan {
      border: 1px solid #dae6f6;
      padding: 21px 21px 0 25px;
      background: url(../../assets/img/bg-plan.svg) no-repeat bottom;
      background-size: contain; }
      @media (min-width: 992px) {
        .prices .prices__plans .plan {
          height: 100%; } }
      @media (max-width: 992px) {
        .prices .prices__plans .plan {
          margin-bottom: 20px; } }
      .prices .prices__plans .plan .plan__details h4 {
        color: #183150;
        font-family: "urw-din";
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 11px; }
        @media (min-width: 768px) {
          .prices .prices__plans .plan .plan__details h4 {
            height: 56px; } }
      .prices .prices__plans .plan .plan__details p {
        opacity: 0.7;
        color: #183150;
        font-size: 14px;
        line-height: 18px;
        padding: 0;
        margin: 0 0 19px; }
        @media (min-width: 768px) {
          .prices .prices__plans .plan .plan__details p {
            height: 60px; } }
        @media (min-width: 768px) and (max-width: 1200px) {
          .prices .prices__plans .plan .plan__details p {
            height: 102px; } }
      .prices .prices__plans .plan .plan__options {
        margin: 0 0 46px; }
        @media (min-width: 768px) {
          .prices .prices__plans .plan .plan__options {
            height: 96px; } }
        @media (max-width: 768px) {
          .prices .prices__plans .plan .plan__options {
            margin-top: 40px; } }
        .prices .prices__plans .plan .plan__options ul {
          list-style: none;
          margin: 0;
          padding: 0; }
          .prices .prices__plans .plan .plan__options ul li {
            color: #183150;
            font-size: 16px;
            padding: 8px 0 8px 30px;
            position: relative;
            cursor: pointer; }
            .prices .prices__plans .plan .plan__options ul li:before {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              top: 7px;
              left: 0;
              border: 1px solid #b6c2d0;
              background: #fff;
              border-radius: 18px; }
            .prices .prices__plans .plan .plan__options ul li.checked:after {
              content: "";
              position: absolute;
              width: 10px;
              height: 10px;
              top: 11px;
              left: 4px;
              background: #fe243f;
              border-radius: 10px; }
      .prices .prices__plans .plan .plan__price {
        color: #16447c;
        font-family: "urw-din";
        font-size: 90px;
        font-weight: 500;
        line-height: 1;
        text-align: center; }
        .prices .prices__plans .plan .plan__price small {
          font-size: 38px;
          font-weight: 400; }
        .prices .prices__plans .plan .plan__price span {
          font-size: 38px;
          font-weight: 300; }
          .prices .prices__plans .plan .plan__price span.x1 {
            padding-right: 5px; }
        .prices .prices__plans .plan .plan__price div {
          display: inline;
          padding: 0 3px; }
        @media (min-width: 992px) and (max-width: 1200px) {
          .prices .prices__plans .plan .plan__price {
            font-size: 72px; }
            .prices .prices__plans .plan .plan__price small, .prices .prices__plans .plan .plan__price small {
              font-size: 36px; } }
  .prices .special_note p {
    color: #183150;
    font-size: 18px;
    line-height: 26px;
    padding: 10px 31px; }
  .prices .special_price {
    overflow: hidden;
    border: 1px solid #dae6f6;
    border-bottom: none;
    padding: 41px 106px 39px 31px; }
    @media (max-width: 768px) {
      .prices .special_price {
        margin-bottom: 20px;
        padding: 41px 31px 39px 31px;
        border-bottom: 1px solid #dae6f6; } }
    .prices .special_price:last-child {
      border-bottom: 1px solid #dae6f6;
      margin-bottom: 0; }
    .prices .special_price h6 {
      float: left;
      color: #16447c;
      font-family: "DIN Alternate";
      font-size: 24px;
      font-weight: 700;
      margin: 0; }
      @media (max-width: 768px) {
        .prices .special_price h6 {
          float: none;
          margin-bottom: 20px; } }
    .prices .special_price div {
      float: right;
      color: #183150;
      font-family: "DIN Alternate";
      font-size: 24px;
      font-weight: 700; }
      .prices .special_price div span {
        font-family: "urw-din";
        font-weight: 400; }
      @media (max-width: 768px) {
        .prices .special_price div {
          float: none; } }
