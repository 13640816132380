.buy {
  background: #175aa8;
  text-align: center;
  color: #fff;
  padding: 106px 0 95px; }
  .buy .col {
    max-width: 535px;
    margin: 0 auto; }
  .buy h4 {
    font-family: "DIN Alternate";
    font-size: 50px;
    font-weight: 700;
    line-height: 79px;
    margin: 0 0 6px; }
  .buy p {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 39px; }
