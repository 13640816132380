.footer {
  background-color: #fff;
  padding: 46px 0 30px; }
  .footer.hidden {
    display: none; }
  @media (max-width: 768px) {
    .footer {
      padding: 0 0 30px; } }
  .footer h6 {
    color: #183150;
    font-family: "Avenir";
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2.57px;
    padding: 0 0 15px;
    border-bottom: 1px solid rgba(24, 49, 80, 0.2); }
    @media (max-width: 768px) {
      .footer h6 {
        padding: 30px 0; } }
  .footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .footer ul li a {
      display: block;
      padding: 12px 0 15px;
      opacity: 0.7;
      color: #183150;
      border-bottom: 1px solid rgba(24, 49, 80, 0.2); }
    @media (max-width: 768px) {
      .footer ul {
        display: none; } }
  .footer p {
    opacity: 0.6;
    color: #183150;
    font-size: 14px;
    padding: 45px 0 0; }
    @media (max-width: 768px) {
      .footer p {
        padding: 40px 0 0;
        line-height: 1.3; } }
  .footer .footer__logo {
    text-align: right;
    padding-top: 50px; }
    .footer .footer__logo img {
      max-width: 158px; }
    @media (max-width: 768px) {
      .footer .footer__logo {
        padding-top: 10px; } }
