@charset "UTF-8";
.home {
  background: url("../../assets/img/large-bg.jpg") no-repeat center;
  background-size: cover;
  height: 100vh; }
  @media (max-width: 991px) {
    .home {
      min-height: 100vh;
      height: auto; } }
  .home::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(24, 49, 80, 0.5); }
  .home .home__grid {
    width: 100%;
    height: 100vh;
    display: flex; }
    .home .home__grid .home__grid--col {
      width: 24%;
      height: 100%;
      position: relative;
      border-right: 1px solid rgba(255, 255, 255, 0.1); }
      .home .home__grid .home__grid--col .number {
        width: 115%;
        position: absolute;
        top: 50%;
        left: -15%;
        transform: translateY(-50%); }
        .home .home__grid .home__grid--col .number img {
          width: 100%;
          max-width: 100%; }
    @media (max-width: 768px) {
      .home .home__grid {
        display: none; } }
  .home .home__text {
    position: relative;
    color: #fff;
    z-index: 2; }
    @media (min-width: 769px) {
      .home .home__text {
        position: absolute;
        top: 50%;
        left: 11.5%;
        transform: translateY(-50%);
        max-width: 42vw; } }
    @media (max-width: 991px) {
      .home .home__text {
        max-width: 55vw; } }
    @media (max-width: 768px) {
      .home .home__text {
        max-width: calc(100% - 40px);
        left: 20px;
        padding-top: 100px; } }
    .home .home__text h1 {
      font-family: "DIN Alternate";
      font-size: 75px;
      font-weight: 700;
      line-height: 79px;
      margin: 0 0 28px;
      padding: 0; }
      @media (max-width: 1100px) {
        .home .home__text h1 {
          font-size: 50px;
          line-height: 53px; } }
    .home .home__text p {
      font-size: 22px;
      font-weight: 400;
      line-height: 32px;
      margin: 0 0 33px;
      padding: 0; }
    .home .home__text .home__btn--wrap {
      display: flex;
      align-items: center; }
      @media (max-width: 768px) {
        .home .home__text .home__btn--wrap {
          flex-wrap: wrap;
          align-items: flex-start;
          flex-direction: column-reverse; } }
      .home .home__text .home__btn--wrap .btn {
        margin-right: 30px; }
      .home .home__text .home__btn--wrap .working {
        padding-left: 36px;
        background: url("../../assets/img/clock.svg") no-repeat left;
        background-size: 25px;
        height: 27px;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        @media (max-width: 768px) {
          .home .home__text .home__btn--wrap .working {
            width: 100%;
            margin-bottom: 20px; } }
        .home .home__text .home__btn--wrap .working h6 {
          margin: 0 0 1px;
          font-size: 16px; }
        .home .home__text .home__btn--wrap .working span {
          color: #7ed321;
          font-size: 16px;
          display: block;
          padding-left: 12px;
          position: relative; }
          .home .home__text .home__btn--wrap .working span:before {
            content: "•";
            color: #7ed321;
            position: absolute;
            left: 0;
            top: -9px;
            font-size: 30px; }
          .home .home__text .home__btn--wrap .working span.working__soon {
            color: #f5a623; }
            .home .home__text .home__btn--wrap .working span.working__soon:before {
              color: #f5a623; }
          .home .home__text .home__btn--wrap .working span.working__closed {
            color: #fe243f; }
            .home .home__text .home__btn--wrap .working span.working__closed:before {
              color: #fe243f; }
  .home__users {
    width: 24%;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    min-width: 339px;
    position: relative; }
    @media (min-width: 992px) {
      .home__users {
        position: absolute;
        right: 4%;
        bottom: 48px; } }
    @media (max-width: 991px) {
      .home__users {
        left: 11.5%;
        margin-top: 50px;
        padding-bottom: 20px; } }
    @media (max-width: 768px) {
      .home__users {
        left: 20px; } }
    @media (max-width: 440px) {
      .home__users {
        min-width: calc(100% - 40px); } }
    .home__users .titles {
      display: flex;
      width: 100%;
      flex-grow: 1; }
      .home__users .titles div {
        width: 50%; }
        .home__users .titles div p {
          color: #ffffff;
          font-family: "DIN Alternate";
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          flex-grow: 1; }
    .home__users .currently, .home__users .reserved {
      width: 50%;
      min-height: 110px; }
    .home__users .currently {
      background-color: #175aa8;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      background-image: url("../../assets/img/users-currently-bg.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom center; }
      .home__users .currently h3 {
        color: #ffffff;
        font-family: "DIN Alternate";
        font-size: 54px;
        font-weight: 700;
        width: 100%;
        margin-bottom: 0;
        line-height: 1.1; }
      .home__users .currently p {
        color: #ffffff;
        font-size: 18px;
        font-weight: 700;
        width: 100%;
        margin-bottom: 0; }
    .home__users .reserved {
      background-color: #fff; }
      .home__users .reserved ul {
        display: flex;
        height: 100%;
        flex-wrap: wrap;
        padding: 0;
        flex-direction: column; }
        .home__users .reserved ul li {
          display: flex;
          flex: 1;
          border-bottom: 1px solid rgba(24, 49, 80, 0.1);
          align-items: center;
          padding: 0 15px;
          color: #183150;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          position: relative; }
          .home__users .reserved ul li:last-child {
            border: none; }
          .home__users .reserved ul li p {
            position: relative;
            z-index: 1;
            margin-bottom: 0; }
          .home__users .reserved ul li .fill {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            background-color: #d9e5f0;
            z-index: 0; }
