.book--view {
  background-color: #16447c;
  background-size: 100%;
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center; }
  @media (max-width: 991px) {
    .book--view {
      height: auto; } }
  .book--view .absolute-bg {
    position: fixed;
    width: 110%;
    left: -5%;
    bottom: -25%; }
  .book--view .description {
    padding-top: 60px;
    padding-bottom: 50px; }
    @media (max-width: 991px) {
      .book--view .description {
        padding: 50px 15px 20px;
        margin: 0 auto;
        text-align: center; } }
    .book--view .description h1 {
      color: #ffffff;
      font-family: "DIN Alternate";
      font-size: 75px;
      font-weight: 700;
      line-height: 79px; }
      @media (max-width: 991px) {
        .book--view .description h1 {
          font-size: 40px;
          line-height: 45px; } }
    .book--view .description h5 {
      color: #ffffff;
      font-family: "DIN Alternate";
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 10px; }
      @media (max-width: 991px) {
        .book--view .description h5 {
          font-size: 20px;
          line-height: 28px; } }
  .book--view .visit {
    padding: 0 30px; }
    @media (max-width: 991px) {
      .book--view .visit {
        padding: 0 15px; } }
    .book--view .visit .link-wrap {
      position: relative;
      margin-bottom: 30px; }
      @media (max-width: 991px) {
        .book--view .visit .link-wrap {
          text-align: center;
          max-width: 370px;
          margin: 0 auto 30px; } }
    .book--view .visit .link {
      background-color: #fff;
      padding: 25px 30px 80px;
      color: #183150;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .book--view .visit .link:hover {
        background-color: #fe243f;
        color: #fff;
        transition: all 0.2s ease-in-out; }
        .book--view .visit .link:hover span {
          opacity: 1; }
      .book--view .visit .link h4 {
        font-family: "DIN Alternate";
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 20px;
        min-height: 100px;
        max-width: 230px; }
        @media (max-width: 991px) {
          .book--view .visit .link h4 {
            margin: 0 auto 20px; } }
      .book--view .visit .link h6 {
        font-family: "DIN Alternate";
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 9px; }
      .book--view .visit .link span {
        opacity: 0.4;
        font-family: "Source Sans Pro";
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 10px;
        display: inline-flex; }
      .book--view .visit .link p {
        font-family: "Source Sans Pro";
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        margin-bottom: 23px;
        padding-left: 13px;
        position: relative; }
        @media (max-width: 991px) {
          .book--view .visit .link p {
            max-width: 230px;
            margin: 0 auto 23px; } }
        .book--view .visit .link p:before {
          content: '*';
          position: absolute;
          left: 0;
          top: 4px; }
      .book--view .visit .link button {
        position: absolute;
        bottom: 0;
        left: 15px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 30px);
        height: 82px;
        background-color: #fe243f;
        color: #ffffff;
        font-family: "Source Sans Pro";
        font-size: 19px;
        font-weight: 400;
        border: none;
        cursor: pointer; }

.content {
  padding: 34px 64px; }
  @media (max-width: 576px) {
    .content {
      padding: 34px 45px; } }
  .content .active-step {
    justify-content: space-between;
    margin-bottom: 55px; }
    .content .active-step div {
      height: 2px;
      background-color: #d3dce4;
      flex: 0 0 18%; }
      .content .active-step div.active {
        background-color: #fe243f; }
  .content .header {
    max-width: 552px;
    margin-bottom: 40px; }
    .content .header h2 {
      color: #183150;
      font-family: "DIN Alternate";
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      margin-bottom: 8px; }
      @media (max-width: 576px) {
        .content .header h2 {
          font-size: 30px;
          line-height: 36px; } }
    .content .header p {
      color: #183150;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px; }
      @media (max-width: 576px) {
        .content .header p {
          font-size: 16px;
          line-height: 24px; } }
  .content .choose-pool {
    justify-content: space-between; }
    .content .choose-pool .pool {
      border: 1px solid #dae6f6;
      padding: 24px 28px;
      text-decoration: none;
      margin-bottom: 12px;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .content .choose-pool .pool:hover {
        background-color: #fe243f;
        transition: all 0.2s ease-in-out; }
        .content .choose-pool .pool:hover h3, .content .choose-pool .pool:hover li {
          color: #fff;
          transition: all 0.2s ease-in-out; }
        .content .choose-pool .pool:hover h3 {
          background-image: url("../../assets/img/arrow-front-white.svg"); }
      @media (min-width: 992px) {
        .content .choose-pool .pool {
          flex: 0 0 calc(50% - 6px); } }
      .content .choose-pool .pool h3 {
        color: #183150;
        font-family: "DIN Alternate";
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
        background-image: url("../../assets/img/arrow-front.svg");
        background-position: center right;
        background-repeat: no-repeat;
        transition: all 0.2s ease-in-out; }
      .content .choose-pool .pool ul {
        padding: 0;
        margin: 0; }
        .content .choose-pool .pool ul li {
          list-style: none;
          margin-bottom: 13px;
          display: inline-flex;
          flex-wrap: wrap;
          width: 100%;
          align-items: center;
          color: #183150;
          font-size: 18px;
          font-weight: 400;
          position: relative;
          min-height: 34px;
          padding-left: 50px;
          transition: all 0.2s ease-in-out; }
          .content .choose-pool .pool ul li span {
            position: absolute;
            left: 0;
            width: 34px;
            height: 34px;
            background-color: #fe243f;
            border-radius: 50%;
            margin-right: 16px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #fff; }
            .content .choose-pool .pool ul li span img {
              display: block;
              max-width: 70%;
              max-height: 70%; }
  .content .rules {
    border-bottom: 1px solid #ecf2fa; }
    .content .rules .rule {
      display: flex;
      align-items: center;
      margin-bottom: 60px;
      padding: 0 30px; }
      @media (max-width: 1199px) {
        .content .rules .rule {
          padding: 0 10px; } }
      .content .rules .rule img {
        margin-right: 28px; }
      .content .rules .rule p, .content .rules .rule b {
        color: #183150;
        font-size: 18px;
        font-weight: 400;
        max-width: 217px;
        margin-bottom: 5px;
        display: inline-block;
        float: left;
        clear: both; }
      .content .rules .rule b {
        font-weight: 600; }
  .content .accept-rules {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 35px 0; }
    @media (max-width: 1100px) {
      .content .accept-rules {
        flex-wrap: wrap; } }
    .content .accept-rules p {
      margin: 0;
      padding-right: 20px; }
      @media (max-width: 1100px) {
        .content .accept-rules p {
          margin: 0 0 30px 0; } }
      @media (max-width: 767px) {
        .content .accept-rules p {
          padding: 0; } }
    @media (max-width: 767px) {
      .content .accept-rules label {
        width: 100%; } }
  .content .select-count {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap; }
    .content .select-count .select-wrap {
      width: calc(100% - 300px); }
      @media (max-width: 1199px) {
        .content .select-count .select-wrap {
          width: 100%;
          margin-bottom: 50px; } }
    .content .select-count .react-select__control {
      height: 55px;
      border: none;
      border-bottom: 1px solid #183150;
      border-radius: 0; }
    .content .select-count .react-select__control--is-focused {
      border-bottom: 1px solid #183150;
      box-shadow: none; }
    .content .select-count .react-select__control:focus, .content .select-count .react-select__control:hover {
      border-color: #183150;
      opacity: 0.9; }
    .content .select-count .react-select__control--menu-is-open {
      border-bottom: 1px solid #183150; }
    .content .select-count .react-select__value-container, .content .select-count .react-select__placeholder {
      color: #183150;
      font-size: 24px;
      font-weight: 600;
      line-height: 26px; }
    .content .select-count .react-select__indicator-separator {
      display: none; }
    .content .select-count .react-select__dropdown-indicator {
      position: relative; }
      .content .select-count .react-select__dropdown-indicator:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border-left: 1px solid #183150;
        border-bottom: 1px solid #183150;
        top: 4px;
        left: 3px;
        transform: rotate(-45deg); }
      .content .select-count .react-select__dropdown-indicator svg {
        display: none; }
    .content .select-count .react-select__menu {
      box-shadow: 0 10px 12px rgba(24, 49, 80, 0.05);
      border-radius: 3px;
      border: 1px solid #dae6f6;
      background-color: #ffffff;
      padding: 0; }
    .content .select-count .react-select__menu-list {
      padding: 0; }
    .content .select-count .react-select__option {
      color: #183150;
      font-size: 24px;
      font-weight: 600;
      height: 80px;
      line-height: 80px;
      padding: 0 32px; }
    .content .select-count .react-select__option--is-selected {
      color: #fff; }
    .content .select-count .react-select__option--is-focused {
      color: #fff; }
    .content .select-count .description {
      margin-top: 13px;
      padding: 0 10px; }
      .content .select-count .description p {
        opacity: 0.6;
        color: #183150;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px; }
        @media (max-width: 1199px) {
          .content .select-count .description p {
            margin-bottom: 30px; } }
  .content .choose-checkout h3 {
    color: #183150;
    font-size: 28px;
    font-weight: 500;
    line-height: 38px;
    margin-bottom: 30px; }
  .content .choose-checkout p {
    color: #183150;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 40px; }
  .content .choose-checkout .without-registration {
    padding: 0 40px;
    border-right: 1px solid #d3dce4; }
    @media (max-width: 1299px) {
      .content .choose-checkout .without-registration {
        padding: 0 20px 0 0; } }
    @media (max-width: 576px) {
      .content .choose-checkout .without-registration {
        padding: 0;
        border: none;
        margin-bottom: 80px; } }
    @media (max-width: 576px) {
      .content .choose-checkout .without-registration h3 {
        margin-bottom: 20px; } }
    .content .choose-checkout .without-registration p {
      min-height: 57px;
      margin-bottom: 50px; }
      @media (max-width: 576px) {
        .content .choose-checkout .without-registration p {
          margin-bottom: 10px; } }
    .content .choose-checkout .without-registration form {
      text-align: center; }
      .content .choose-checkout .without-registration form .select-wrap {
        margin-bottom: 20px; }
    .content .choose-checkout .without-registration.logged-in {
      border: none;
      max-width: 580px; }
      .content .choose-checkout .without-registration.logged-in h3 {
        margin-bottom: 20px; }
      .content .choose-checkout .without-registration.logged-in p {
        margin-bottom: 20px; }
      .content .choose-checkout .without-registration.logged-in form {
        text-align: left; }
  .content .choose-checkout .with-profile {
    padding: 0 40px;
    text-align: center; }
    @media (max-width: 1299px) {
      .content .choose-checkout .with-profile {
        padding: 0 0 0 20px; } }
    @media (max-width: 576px) {
      .content .choose-checkout .with-profile {
        padding: 0; } }
    .content .choose-checkout .with-profile .forgot-pass {
      opacity: 0.5;
      color: #183150;
      font-size: 16px;
      font-weight: 400;
      text-decoration: underline;
      display: block;
      text-align: right;
      margin-bottom: 25px;
      margin-top: -40px; }
    .content .choose-checkout .with-profile .btn {
      margin-bottom: 20px; }
    .content .choose-checkout .with-profile .register {
      color: #183150;
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      text-decoration: underline;
      align-self: center; }
    .content .choose-checkout .with-profile .btn-wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap; }
      .content .choose-checkout .with-profile .btn-wrap button {
        width: 47%; }
        @media (max-width: 1400px) {
          .content .choose-checkout .with-profile .btn-wrap button {
            width: 100%;
            max-width: 100%; } }
        @media (max-width: 576px) {
          .content .choose-checkout .with-profile .btn-wrap button {
            max-width: 247px;
            margin: 0 auto 20px; } }

.summary {
  background-color: #16447c;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 100px; }
  .summary .absolute-bg {
    position: fixed;
    width: 110%;
    left: -5%;
    bottom: -40vh; }
  .summary .back-link {
    cursor: pointer;
    position: absolute;
    width: 28px;
    top: 35px;
    left: 64px;
    z-index: 10;
    display: block;
    height: 17px;
    background-image: url("../../assets/img/back-arrow.svg"); }
    @media (max-width: 576px) {
      .summary .back-link {
        left: 15px; } }
  .summary .session {
    position: absolute;
    top: 35px;
    right: 50px;
    z-index: 10; }
    @media (max-width: 576px) {
      .summary .session {
        right: 15px; } }
    .summary .session p {
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0; }
      .summary .session p.session-time {
        color: #ffffff;
        font-family: "DIN Alternate";
        font-size: 28px;
        font-weight: 400;
        line-height: 48px;
        padding-left: 45px;
        background-image: url("../../assets/img/session-time.svg");
        background-position: center left 5px;
        background-repeat: no-repeat; }
  .summary h1 {
    padding: 90px 0 50px;
    text-align: center;
    color: #fff;
    font-size: 48px;
    font-weight: 700;
    line-height: 58px; }
  .summary__content {
    max-width: 580px;
    margin: 0 auto 90px;
    background: #fff;
    border-top: 4px solid #fe243f;
    position: relative; }
    @media (max-width: 610px) {
      .summary__content {
        margin: 0 15px 90px; } }
    .summary__content .item {
      margin: 0 30px;
      border-bottom: 1px solid #dae6f6;
      padding: 20px 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative; }
      .summary__content .item:nth-last-child(3) {
        border-bottom: 1px solid transparent; }
      .summary__content .item p {
        margin-bottom: 0;
        color: #183150;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px; }
        .summary__content .item p span {
          font-weight: 400; }
      .summary__content .item div:nth-child(2) p {
        font-weight: 400; }
      .summary__content .item b {
        color: #183150;
        font-size: 25px;
        font-weight: 600;
        line-height: 26px;
        text-transform: uppercase; }
      .summary__content .item .description {
        opacity: 0.6;
        color: #183150;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        margin-top: 10px; }
      .summary__content .item.featured {
        position: relative;
        padding: 30px 0; }
        .summary__content .item.featured:before, .summary__content .item.featured:after {
          content: '';
          display: block;
          position: absolute;
          bottom: -15px;
          width: 15px;
          height: 30px;
          background: #16447c; }
        .summary__content .item.featured:before {
          left: -30px;
          border-bottom-right-radius: 60px;
          border-top-right-radius: 60px; }
        .summary__content .item.featured:after {
          right: -30px;
          border-bottom-left-radius: 60px;
          border-top-left-radius: 60px; }
        .summary__content .item.featured p {
          color: #183150;
          font-size: 24px;
          font-weight: 600;
          line-height: 26px; }
    .summary__content .select-wrap .react-select__control {
      border: none;
      box-shadow: none; }
      .summary__content .select-wrap .react-select__control--is-focused {
        border: none; }
      .summary__content .select-wrap .react-select__control--menu-is-open {
        border: none; }
    .summary__content .select-wrap .react-select__value-container {
      width: 110px;
      text-align: right; }
    .summary__content .select-wrap .react-select__indicator-separator {
      display: none; }
    .summary__content .select-wrap .react-select__single-value, .summary__content .select-wrap .react-select__placeholder {
      color: #183150;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px; }
    .summary__content .select-wrap .react-select__dropdown-indicator {
      position: relative; }
      .summary__content .select-wrap .react-select__dropdown-indicator:after {
        content: '';
        position: absolute;
        margin-top: -3px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 4px 0 4px;
        border-color: #183150 transparent transparent transparent; }
      .summary__content .select-wrap .react-select__dropdown-indicator svg {
        display: none; }
    .summary__content .select-wrap .react-select__menu {
      box-shadow: 0 10px 12px rgba(24, 49, 80, 0.05);
      border-radius: 3px;
      border: 1px solid #dae6f6;
      background-color: #ffffff;
      padding: 0; }
    .summary__content .select-wrap .react-select__menu-list {
      padding: 0; }
    .summary__content .select-wrap .react-select__option {
      color: #183150;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px; }
    .summary__content .select-wrap .react-select__option--is-selected {
      color: #183150;
      background: transparent; }
    .summary__content .select-wrap .react-select__option--is-focused {
      color: #fff;
      background: #183150; }
    .summary__content .accept-rules {
      padding: 35px 30px 35px; }
      .summary__content .accept-rules div {
        top: 10px; }
    .summary__content .pay-button {
      background-color: #fe243f;
      width: 100%;
      min-height: 93px;
      color: #ffffff;
      font-size: 19px;
      font-weight: 400;
      border: none;
      cursor: pointer; }
      .summary__content .pay-button:focus {
        outline: none; }
      .summary__content .pay-button:disabled {
        opacity: .65; }

.confirmation__content {
  width: 100%;
  max-width: 581px;
  margin: auto;
  text-align: center;
  z-index: 1;
  position: relative; }
  @media (min-width: 768px) {
    .confirmation__content {
      padding: 0 15px; } }
  .confirmation__content h1 {
    padding: 25vh 0 50px; }
    @media (max-width: 576px) {
      .confirmation__content h1 {
        padding: 10vh 0 50px; } }
  .confirmation__content .description {
    color: #ffffff;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 40px; }
  .confirmation__content .seperator_or {
    color: #ffffff;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    position: relative;
    margin-bottom: 40px; }
    .confirmation__content .seperator_or:before, .confirmation__content .seperator_or:after {
      content: '';
      width: 35%;
      height: 1px;
      background: rgba(74, 150, 210, 0.3);
      color: #456996;
      position: absolute;
      top: 50%; }
    .confirmation__content .seperator_or:before {
      left: 10%; }
    .confirmation__content .seperator_or:after {
      right: 10%; }
    @media (max-width: 576px) {
      .confirmation__content .seperator_or:before {
        left: 5%; }
      .confirmation__content .seperator_or:after {
        right: 5%; } }
  .confirmation__content .btn {
    margin-bottom: 28px;
    max-width: 285px; }
  .confirmation__content .home-link {
    cursor: pointer;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline; }
